//customer Detail
.customerDetail-page__main {
  .page--info {
    display: flex;
    justify-content: space-between;
    .page--info--inner {
      display: flex;
      align-items: center;
      .customer-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .customer-name {
          font-style: normal;
          font-weight: 500;
          font-size: 1.625rem;
          line-height: 2.063rem;
          color: $input-value;
          margin-bottom: 0.7rem;
        }
        .customer-role {
          .customer_roles-select__box {
            margin: 0;
            width: 14rem;
            .react-select {
              .react-select__control {
                border: 1px solid $gray-200;
                border-radius: 4px !important;
                .react-select__value-container {
                  padding: 0.1rem 0.5rem;
                }
              }
            }
          }
        }
        .customer-info {
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.125rem;
          color: $input-value;
        }
      }
      .customer-detail-action__block {
        display: inline-flex;
        .customer-detail--block {
          .customer--detail {
            color: #f5604c;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            font-weight: 500;
            font-size: 0.9rem;
            .dot--icon {
              display: inline-flex;
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
      }
    }
    .customerDetail-dropdown_btn {
      .dropdown-icon > svg {
        color: $gray-300;
        font-size: 2rem;
      }
    }
  }
  .customerDetail-table {
    margin-top: 1rem;
    .customerDetail-table-heading {
      margin: 1rem;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1.4rem;
      border-bottom: 1px solid $tab-active-color;
    }
    .customerDetail-list-table {
      .item-icon {
        padding-right: 1rem;
      }
    }
  }
}

// customerProfile-tab
.customerProfile-details__main {
  .customerProfile-details-inner {
    .customerProfile--content {
      margin-bottom: 1rem;
      .customerProfile-details--wrapper {
        margin-bottom: 1.5rem;
      }
    }
  }
}

//customerBooking-tab

.customerBooking-table {
  margin-top: 1rem;
  .customerBooking-table-heading-row {
    border-bottom: 1px solid $tab-active-color;
    .customerBooking-table-heading {
      margin: 1rem;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1.4rem;
    }
    .customerBooking-filter-block {
      display: flex;
      padding: 0.9rem;
      .customerBooking-filter-icon {
        display: flex;
        align-items: center;
        .customerBooking-filter-label {
          padding-left: 0.5rem;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
      .customerBooking-arrow__icon {
        color: $brand-accent;
        margin-left: auto;
      }
    }
    .customerBooking-filter-dropdown {
      min-width: 12rem;
      margin-right: 1rem;
      @media screen and (max-width: 1440px) {
        min-width: 10rem;
      }
      @media screen and (max-width: 1024px) {
        min-width: 12rem;
      }
      @media screen and (max-width: 768px) {
        min-width: 12rem;
      }
    }
    .customerBooking-search-button {
      border: none;
      cursor: pointer;
      border-radius: 5px;
      padding: 0.9rem 1.5rem;
      font-size: 0.9rem;
    }
    .customerBooking-search-excel-block {
      padding-left: 0.5rem;
      // padding-top: 0.5rem;
    }
    .customerBooking-excel-icon {
      cursor: pointer;
      margin-top: 0.5rem;
      margin-left: 1.5rem;
      @media screen and (max-width: 1024px) {
        margin-left: 0rem;
      }

      @media screen and (max-width: 768px) {
        margin-left: 0rem;
      }
      .download-table-xls-button {
        color: $special-excel;
        background: transparent;
        border: none;
        svg {
          height: 2rem;
        }
      }
    }
  }
  .customerBooking-list-table {
    .station-link {
      cursor: pointer;
    }
    .station-link:hover {
      text-decoration: underline;
    }
    .item-icon {
      padding-right: 1rem;
    }
  }
}

.status-blocks {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  // align-items: center;
  margin-bottom: 20px;
  flex-wrap:nowrap;
  transition: 0.1s;
  @media (max-width:768px) {
    width:100%;
    margin-bottom:1rem;
  }
}

.status-block {
  width: auto;
  background-color: $brand-primary;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.7rem;
  border-radius: 20px;
  cursor: pointer;
  margin: 5px;
  box-sizing: border-box;
  transition: 0.1s;
}

.status-block:hover {
  background-color: $brand-primary;
  filter: contrast(95%);
}

.breating-area {
  background-color: $gray-700;
  padding: 1rem;
  border-radius: 20px;
  transition: 0.1s;
}

.status-info {
  width: 100%;
  /* background-color: bisque; */
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  transition: 0.1s;
}

.status-info h1 {
  margin: 0px;
  padding: 0px;
  font-size: 20px;

  @media (max-width:768px){
    font-size:1.5rem;
  }
  @media (max-width:425px){
    font-size:1.2rem;
  }
}

.status-info p {
  margin: 0px;
  padding: 0px;
  font-weight: 100;
  font-size: 12px;
  letter-spacing: 0.1px;

  @media (max-width:768px){
    font-size:0.9rem;
  }
  @media (max-width:425px){
    font-size:0.8rem;
  }
}
//temporary patch for the widgets breaking in the following resolution (252)
@media (min-width: 1200px) and (max-width: 1366px) { 
  .status-block {
    margin: 0px 2px;
    height: 7em;
  }
  .status-info {
    p {@media (max-width: 768px) {
  .status-block {
    margin: 0px 1px; // Reduce margin for compactness
    height: 5em; // Decrease height to make widgets smaller
  }

  .status-info {
    p {
      font-size: 0.5em; // Reduce font size for mobile screens
      display: block; // Ensure consistent layout
      letter-spacing: 0px; // Maintain readability
    }
  }
}

      font-size: 0.65em;
      display: block;
      letter-spacing: 0px;
    }
  }
}
@media (min-width: 1001px) and (max-width: 1202px) {
  .status-blocks {
    gap: 10px;
    .status-block {
      padding: 0.7em;
      margin: 0px !important;
      svg {
        height: 20px;
        width: 20px;
      }
      .status-info {
        p {
          font-size: 13px;
          letter-spacing: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1705px) {
  .status-blocks {
    width: 100%;
    height: auto;
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .status-block {
    width: 48%;
    background-color: $brand-primary;
    height: 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    gap:5px
  }
}
@media only screen and (max-width: 1024px) {
  .status-blocks {
    width: 100%;
    height: auto;
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .status-block {
    width: 48%;
    background-color: $brand-primary;
    height: 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
  }
}

@media only screen and (max-width: 500px) {
  .status-blocks {
    width: 100%;
    height: auto;
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .status-block {
    width: 48%;
    background-color: $brand-primary;
    height: 7.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    margin: 0px;
  }
  .status-blocks .status-block svg {
    height: 20px;
    width: 20px;
  }
  .status-info p {
    letter-spacing: 0px;
  }
  .status-info {
    padding: 5px;
  }
}

.more-button {
  margin-left: 15px;
  border: 1px solid $special-billing;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.more-button:hover {
  background-color: $special-billing;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $brand-accent;
  padding: 1px 5px;
  cursor: pointer;
  border-radius: 0px 5px 0px 0px;
  margin: 1px;
}

.close-button:hover {
  background-color: $button-primary-hover;
}

@media only screen and (max-width: 768px) {
  .status-blocks {
    display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr)); 
    gap: 30px; 
    width: 100%; 
    height: auto; 
    padding: 10px; 
    align-items: center;
    justify-content: center; 
  }

  .status-block {
    width: auto; 
    height: 6rem; 
    background-color: $brand-primary; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    padding: 5px; 
    border-radius: 8px; 
    margin: 0; 
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
   
}
  }

  .status-block svg {
    height: 18px; 
    width: 18px;
  }

    .status-info {
      display: flex;
      padding: 4px;
      text-align: center;
      flex-direction: column;
      align-items: flex-start;
  }
  

  .status-info p {
    font-size: 0.85rem; 
    letter-spacing: 0; 
    
  }

  .more-button {
    margin: 10px auto; 
    border: 1px solid $special-billing; 
    border-radius: 5px; 
    text-align: center; 
    padding: 5px; 
    cursor: pointer;
    width: auto; 
  }


@media only screen and (max-width: 350px) {
.status-blocks{

  
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  
    .status-block {
      list-style: none;
      padding: 10px 20px;
      margin-bottom: 10px;
      border-radius: 5px;
      background-color: #1b2a38;
      transition: background-color 0.3s ease;
      width:170px;
  
      &:hover {
        background-color: #1b2a38;
      }

      .status-info {
        width: 100%;
    
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        padding: 10px;
        transition: 0.1s;
      
        .status-info h1{
          display: block;
        }

        .status-info p{
          display: block;
        }


        
        @media (max-width: 350px) {
          flex-direction: column; 
          justify-content: center; 
          align-items: center; 
          padding: 5px; 
        }
      }
    }
  

  }
  
}

@media (min-width:360px) and (max-width:427px){
  .status-blocks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 2fr));
    gap: 30px;
    width: 100%;
    height: auto;
    padding: 10px;
    align-items: center;
    justify-content: center;
}
.status-block {
  list-style: none;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #1b2a38;
  transition: background-color 0.3s ease;
  width:160px;
  gap:1px;
}

.status-info p {
  font-size: 0.85rem;
  letter-spacing: 0;
  text-align: left;
}
}
@media (max-width:375px) and (min-width:330px){
  .status-info p {
    font-size: 0.85rem;
    letter-spacing: 0;
    text-align: left;
}
}